import enString from "./en"
import ptString from "./pt"
import frString from "./fr"

export interface ITimeLang {
    months: string[]
    weekdays: string[]
}

interface ITimeStrings {
    [key: string]: ITimeLang
}

export const timeStrings: ITimeStrings = {
    en: enString,
    pt: ptString,
    fr: frString
}
